import { AspectRatio, Box, Heading, SimpleGrid } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import { useNavigate } from "react-router-dom";

const ReleaseDigitalWorkforce = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const breadcrumbRoutes = [
    { label: "menu.home", path: "/" },
    { label: "menu.workbench", path: "/workbench" },
    {
      label: "menu.release-digital-workforce",
      path: "/workbench/release-digital-workforce",
    },
  ];
  const items = [
    {
      title: t("release-digital-workforce.items.task"),
      path: "/workbench/release-digital-workforce/task",
    },
    {
      title: t("release-digital-workforce.items.function"),
      path: "/workbench/release-digital-workforce/function",
    },
    {
      title: t("release-digital-workforce.items.role"),
      path: "/workbench/release-digital-workforce/role",
    },
  ];
  return (
    <Box maxW="1240px" margin="0 auto" p="1rem">
      <Box>
        <CustomBreadcrumb routes={breadcrumbRoutes} />
      </Box>
      <Box minH="calc(100vh - 120px)">
        <Heading textAlign="center" mb={{ base: "2rem", md: "5rem" }}>
          {t("menu.release-digital-workforce")}
        </Heading>
        <SimpleGrid columns={{ base: 1, md: 4 }} spacing={5}>
          {items.map((item, key) => (
            <AspectRatio ratio={1} key={key}>
              <Box
                bg="#fff"
                borderRadius="md"
                p="1rem"
                textAlign="center"
                display="flex"
                alignItems="center"
                justifyContent="center"
                onClick={() => navigate(item.path)}
                boxShadow="small"
                transition="transform 0.3s"
                _hover={{
                  boxShadow: "lg",
                  rounded: "md",
                  cursor: "pointer",
                  transform: "translateY(-6px)",
                  color: "teal",
                }}
              >
                <Heading size="lg">{item.title}</Heading>
              </Box>
            </AspectRatio>
          ))}
        </SimpleGrid>
      </Box>
    </Box>
  );
};

export default ReleaseDigitalWorkforce;
