import * as Yup from "yup";

export const authValidationSchema = Yup.object().shape({
  email: Yup.string()
    .max(32, "Email must not exceed 32 characters")
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .max(32, "Password must not exceed 32 characters")
    .required("Password is required"),
});

export const forgotValidationSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, "New Password must be at least 6 characters")
    .max(32, "New Password must not exceed 32 characters")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/,
      "New Password must contain at least one letter, one number, and one special character"
    )
    .required("New Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "Confirm Password must match")
    .required("Confirm password is required"),
});

export const registerValidationSchema = Yup.object().shape({
  email: Yup.string()
    .max(32, "Email must not exceed 32 characters")
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .max(32, "Password must not exceed 32 characters")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/,
      "Password must contain at least one letter, one number, and one special character"
    )
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "Password must match")
    .required("Confirm password is required"),
});

export const organizationInfoValidationSchema = Yup.object().shape({
  name: Yup.string()
    .max(32, "Organization name must not exceed 32 characters")
    .required("Organization name is required"),
  description: Yup.string()
    .nullable()
    .max(500, "Organization description must not exceed 500 characters"),
  number: Yup.number()
    .nullable()
    .max(100000, "Organization number must not exceed 100,000"),
});

export const organizationInviteValidationSchema = Yup.object().shape({
  email: Yup.string()
    .max(32, "Email must not exceed 32 characters")
    .email("Invalid email address")
    .required("Email is required"),
});

export const forgotPasswordValidationSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .max(32, "Password must not exceed 32 characters")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/,
      "Password must contain at least one letter, one number, and one special character"
    )
    .required("Password is required"),
});

export const inviteValidationSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .max(32, "Password must not exceed 32 characters")
    .required("Password is required"),
});

export const caseValidationSchema = Yup.object().shape({
  title: Yup.string()
    .max(255, "Case title must not exceed 255 characters")
    .required("Case title is required"),
  backgroundDesc: Yup.string()
    .max(10000, "Case description must not exceed 10000 characters")
    .required("Case description is required"),
  tags: Yup.array().min(1, "At least one tag is required"),
});

export const digitalWorkforceValidationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
});

export const requirementsSearchValidationSchema = Yup.object({
  title: Yup.string().max(32, "Title must not exceed 32 characters"),
});

export const editRequirementValidationSchema = Yup.object({
  title: Yup.string()
    .required("Title is required")
    .min(6, "Title must be at least 6 characters")
    .max(225, "Title must not exceed 225 characters"),
  // description: Yup.string()
  //   .nullable()
  //   .max(10000, "Requirement description must not exceed 10000 characters"),
  estimatedTime: Yup.number()
    .required("Estimated time is required")
    .min(1, "Estimated time must be at least 1 hour")
    .max(3000, "Estimated time must not exceed 3000 hours"),
});

export const editApplyValidationSchema = Yup.object({
  estimatedTime: Yup.number()
    .required("Estimated time is required")
    .min(1, "Estimated time must be at least 1 hour")
    .max(3000, "Estimated time must not exceed 3000 hours"),
  quotation: Yup.number()
    .required("Quotation is required")
    .min(1, "Quotation must be at least 1")
    .max(10000, "Quotation must not exceed 10000"),
});

export const taskTimelineValidationSchema = Yup.object().shape({
  estimate: Yup.number()
    .min(1, "Estimate must be at least 1")
    .max(3000, "Estimate must be at most 3000")
    .required("Estimate is required"),
  design: Yup.number()
    .min(1, "Design must be at least 1")
    .max(3000, "Design must be at most 3000")
    .required("Design is required"),
  development: Yup.number()
    .min(1, "Development must be at least 1")
    .max(3000, "Development must be at most 3000")
    .required("Development is required"),
  codeReview: Yup.number()
    .min(1, "Code Review must be at least 1")
    .max(3000, "Code Review must be at most 3000")
    .required("Code Review is required"),
  integrationTest: Yup.number()
    .min(1, "Integration Test must be at least 1")
    .max(3000, "Integration Test must be at most 3000")
    .required("Integration Test is required"),
  inspect: Yup.number()
    .min(1, "Inspect must be at least 1")
    .max(3000, "Inspect must be at most 3000")
    .required("Inspect is required"),
  settlement: Yup.number()
    .min(1, "Settlement must be at least 1")
    .max(3000, "Settlement must be at most 3000")
    .required("Settlement is required"),
});

export const profileInfoValidationSchema = Yup.object({
  userName: Yup.string()
    .required("User Name is required")
    .min(1, "User Name must be at least 1 characters")
    .max(64, "User Name must not exceed 64 characters"),
  description: Yup.string().max(
    3000,
    "Description must not exceed 3000 characters"
  ),
});

export const resetPasswordValidationSchema = Yup.object({
  oldPassword: Yup.string()
    .required("Old Password is required")
    .min(6, "Old Password must be at least 6 characters")
    .max(32, "Old Password must not exceed 32 characters"),
  newPassword: Yup.string()
    .required("New Password is required")
    .min(6, "New Password must be at least 6 characters")
    .max(32, "New Password must not exceed 32 characters")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/,
      "Password must contain at least one letter, one number, and one special character"
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword")], "Password must match")
    .required("Confirm password is required"),
});

export const bindEmailValidationSchema = Yup.object({
  email: Yup.string()
    .max(32, "Email must not exceed 32 characters")
    .email("Invalid email address")
    .required("Email is required"),
  verificationCode: Yup.string()
    .length(6, "Verification code must be 6 digits")
    .required("Verification code is required"),
});

export const blogValidationSchema = Yup.object({
  title: Yup.string()
    .required("Blog title is required")
    .min(1, "Blog title must be at least 1 characters")
    .max(500, "Blog title must not exceed 500 characters"),
  content: Yup.string()
    .required("Blog content is required")
    .min(1, "Blog content must be at least 1 characters")
    .max(10000, "Blog content must not exceed 10000 characters"),
});

export const releaseDigitalWorkforceTaskValidationSchema = Yup.object({
  functionName: Yup.string()
    .required("Name is required")
    .min(1, "Name must be at least 1 characters")
    .max(255, "Name must not exceed 255 characters"),
  description: Yup.string().max(
    3000,
    "Description must not exceed 3000 characters"
  ),
  command: Yup.string()
    .required("Command is required")
    .min(1, "Command must be at least 1 characters")
    .max(255, "Command must not exceed 255 characters"),
});

export const releaseDigitalWorkforceTaskSearchValidationSchema = Yup.object({
  functionName: Yup.string().max(255, "Name must not exceed 255 characters"),
});

export const releaseDigitalWorkforceFunctionValidationSchema = Yup.object({
  groupName: Yup.string()
    .required("Name is required")
    .min(1, "Name must be at least 1 characters")
    .max(255, "Name must not exceed 255 characters"),
  groupDescription: Yup.string().max(
    3000,
    "Description must not exceed 3000 characters"
  ),
  // functionIds: Yup.string()
  //   .required("Command is required")
  //   .min(1, "Command must be at least 1 characters")
  //   .max(255, "Command must not exceed 255 characters"),
});

export const releaseDigitalWorkforceFunctionSearchValidationSchema = Yup.object(
  {
    groupName: Yup.string().max(255, "Name must not exceed 255 characters"),
  }
);

export const releaseDigitalWorkforceRoleValidationSchema = Yup.object({
  name: Yup.string()
    .required("Name is required")
    .min(1, "Name must be at least 1 characters")
    .max(255, "Name must not exceed 255 characters"),
  // functionIds: Yup.string()
  //   .required("Command is required")
  //   .min(1, "Command must be at least 1 characters")
  //   .max(255, "Command must not exceed 255 characters"),
});

export const groupConfValidationSchema = Yup.object({
  cronStr: Yup.string()
    .min(1, "cronStr must be at least 1 characters")
    .max(64, "cronStr must not exceed 64 characters"),
});

export const subscribedDigitalWorkforceValidationSchema = Yup.object({
  name: Yup.string()
    .min(1, "Name must be at least 1 characters")
    .max(64, "Name must not exceed 64 characters"),
  // domains: Yup.string()
  // .min(1, "Name must be at least 1 characters")
  // .max(64, "Name must not exceed 64 characters"),
  description: Yup.string()
    .min(1, "Description must be at least 1 characters")
    .max(255, "Description must not exceed 255 characters"),
});
