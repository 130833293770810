import { isEmpty } from "lodash";
import { UserInfo } from "../models/auth";

// 将文件数组转换为包含二进制数据的对象数组的方法
export const convertFilesToBinary = (
  fileList: File[]
): Promise<Array<{ file: File; binary: ArrayBuffer }>> => {
  return new Promise((resolve, reject) => {
    const fileReaders: FileReader[] = [];
    const filesWithBinary: Array<{ file: File; binary: ArrayBuffer }> = [];

    fileList.forEach((file, index) => {
      const reader = new FileReader();

      reader.onload = () => {
        filesWithBinary.push({ file, binary: reader.result as ArrayBuffer });

        // 当所有文件都被处理后，resolve 结果
        if (filesWithBinary.length === fileList.length) {
          resolve(filesWithBinary);
        }
      };

      reader.onerror = () => {
        reject(new Error(`Failed to read file: ${file.name}`));
      };

      reader.readAsArrayBuffer(file);
      fileReaders.push(reader);
    });
  });
};

export const objectToFormData = (obj: { [key: string]: any }) => {
  const formData = new FormData();
  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    if (Array.isArray(value)) {
      value.forEach((item, index) => {
        if (item instanceof File) {
          formData.append(key, item);
        } else if (typeof item === "object" && item !== null) {
          Object.keys(item).forEach((subKey) => {
            formData.append(`${key}[${index}][${subKey}]`, item[subKey]);
          });
        } else {
          formData.append(key, item);
        }
      });
    } else {
      formData.append(key, value);
    }
  });
  return formData;
};

export const arrayToOptions = (arr: string[]) => {
  if (isEmpty(arr)) {
    return [];
  } else {
    return arr.map((item) => ({ label: item, value: item }));
  }
};

export const getCurrentOrganizationName = () => {
  const userInfoJson = localStorage.getItem("userInfo");
  if (userInfoJson) {
    const _userInfo = JSON.parse(userInfoJson) as UserInfo;
    const foundItem = _userInfo.organizations.find(
      (item) => item.id === _userInfo.currentOrganizationId
    );
    return foundItem ? foundItem.name : undefined;
  }
  return undefined;
};

export const getCurrentOrganizationId = () => {
  const userInfoJson = localStorage.getItem("userInfo");
  if (userInfoJson) {
    const _userInfo = JSON.parse(userInfoJson) as UserInfo;
    return _userInfo.currentOrganizationId;
  }
  return undefined;
};

export const getUserInfo = () => {
  const userInfoJson = localStorage.getItem("userInfo");
  return JSON.parse(userInfoJson!) as UserInfo;
};
