import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormErrorMessage,
  useToast,
  Textarea,
} from "@chakra-ui/react";
import { Select as ChakraSelect, MultiValue } from "chakra-react-select";
import { Formik, Form, Field } from "formik";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import {
  ReleaseDIgitalWorkforceFunction,
  ReleaseDIgitalWorkforceTask,
} from "../../../../../models/releaseDigitalWorkforce";
import { releaseDigitalWorkforceFunctionValidationSchema } from "../../../../../utils/validation";
import {
  createReleaseDigitalWorkforceFunction,
  getReleaseDigitalWorkforceTaskList,
} from "../../../../../services/releaseDigitalWorkforce";
import { Options } from "../../../../../models/common";

interface EditOrAddFormProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  initialValues: ReleaseDIgitalWorkforceFunction;
  onSubmit: () => void;
}

const EditReleaseDigitalWorkforceFunction: React.FC<EditOrAddFormProps> = ({
  isOpen,
  onOpen,
  onClose,
  initialValues,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const [selectedTasks, setSelectedTasks] = useState<Options[]>([]);
  const [functionList, setFunctionList] = useState<Options[]>([]);

  useEffect(() => {
    initEditValues(initialValues);
    fetchFunctionList();
  }, [initialValues]);

  const initEditValues = (values: ReleaseDIgitalWorkforceFunction) => {
    setSelectedTasks(functionArrayToOptions(values.functions!));
  };

  const fetchFunctionList = () => {
    getReleaseDigitalWorkforceTaskList().then((res) => {
      setFunctionList(functionArrayToOptions(res));
    });
  };

  const functionArrayToOptions = (arr: ReleaseDIgitalWorkforceTask[]) => {
    if (isEmpty(arr)) {
      return [];
    } else {
      return arr.map((item) => ({ label: item.functionName, value: item.id! }));
    }
  };

  const onTasksChange = (
    selectedOptions: MultiValue<Options>,
    field: { name: any },
    form: { setFieldValue: (arg0: any, arg1: any) => void }
  ) => {
    setSelectedTasks(selectedOptions as Options[]);
    form.setFieldValue(field.name, selectedOptions);
  };

  const handleCreate = (
    values: any,
    formikHelpers: { setSubmitting: (arg0: boolean) => void }
  ) => {
    const editData: any = {
      groupName: values.groupName,
      groupDescription: values.groupDescription,
    };
    let opt = "create";
    if (!isEmpty(values.id)) {
      editData.id = values.id;
      opt = "update";
    }
    if (!isEmpty(selectedTasks)) {
      editData.functionIds = selectedTasks.map((option) => option.value);
    }
    createReleaseDigitalWorkforceFunction(editData)
      .then((res) => {
        onClose();
        onSubmit();
        toast({
          title: t("common.btn." + opt + "-success-msg"),
          position: "top",
          status: "success",
          isClosable: true,
          duration: 3000,
        });
        formikHelpers.setSubmitting(false);
      })
      .catch((error) => {
        toast({
          title: error?.response?.data,
          position: "top",
          status: "error",
          isClosable: true,
          duration: 3000,
        });
        formikHelpers.setSubmitting(false);
      });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent minW={{ md: "50rem" }}>
        <ModalHeader>
          {!!initialValues.id
            ? t("release-digital-workforce.function.edit")
            : t("release-digital-workforce.function.create")}
        </ModalHeader>
        <ModalCloseButton />
        <Formik
          initialValues={initialValues}
          validationSchema={releaseDigitalWorkforceFunctionValidationSchema}
          onSubmit={handleCreate}
        >
          {(props) => (
            <Form>
              <ModalBody>
                <Box my="0.5rem">
                  <Field name="groupName">
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={
                          form.errors.groupName && form.touched.groupName
                        }
                        isRequired
                      >
                        <FormLabel>
                          {t("release-digital-workforce.function.name")}:
                        </FormLabel>
                        <Input {...field} />
                        <FormErrorMessage>
                          {form.errors.groupName}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Box>
                <Box my="0.5rem">
                  <Field name="groupDescription">
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={
                          form.errors.groupDescription &&
                          form.touched.groupDescription
                        }
                      >
                        <FormLabel>
                          {t("release-digital-workforce.function.desc")}:
                        </FormLabel>
                        <Textarea {...field} minH="10rem" />
                        <FormErrorMessage>
                          {form.errors.groupDescription}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Box>
                <Box my="0.5rem">
                  <Field name="functionIds">
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={
                          form.errors.functionIds && form.touched.functionIds
                        }
                        isRequired
                      >
                        <FormLabel>
                          {t("release-digital-workforce.items.task")} :
                        </FormLabel>
                        <ChakraSelect
                          isMulti
                          colorScheme="purple"
                          options={functionList}
                          value={selectedTasks}
                          onChange={(selectedOptions) =>
                            onTasksChange(selectedOptions, field, form)
                          }
                        />
                        <FormErrorMessage>
                          {form.errors.functionIds}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Box>
              </ModalBody>
              <ModalFooter>
                <Button type="submit" size="sm" colorScheme="teal" mr={3}>
                  {t("common.btn.submit")}
                </Button>
                <Button size="sm" onClick={onClose}>
                  {t("common.btn.cancel")}
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

export default EditReleaseDigitalWorkforceFunction;
