import {
  Avatar,
  Box,
  Button,
  Flex,
  Heading,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Alert,
  AlertIcon,
  Center,
  HStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DigitalWorkforceRow } from "../../models/digitalWorkforce";
import TagSelector from "../../components/TagSelector";
import {
  cancelSubscribeDigitalWorkforce,
  getDigitalWorkforceList,
  subscribeDigitalWorkforce,
} from "../../services/digitalWorkforceService";
import { domainList } from "../../constants/constants";
import { getCurrentOrganizationId, getUserInfo } from "../../utils/common";
import { canEdit, isPlatformAdmin } from "../../utils/auth";
import { useNavigate } from "react-router-dom";
import CustomBreadcrumb from "../../components/CustomBreadcrumb";
import {
  _renderDigitalWorkforceStatus,
  _renderDigitalWorkforceType,
} from "../../utils/digitalWorkforce";
import { isEmpty } from "lodash";
interface DigitalWorkforceProps {
  isFromWorkbench?: boolean;
}
const DigitalWorkforce: React.FC<DigitalWorkforceProps> = ({
  isFromWorkbench = false,
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();
  const textColor = useColorModeValue("gray.700", "white");
  const breadcrumbRoutes = [
    { label: "menu.home", path: "/" },
    { label: "menu.digital-workforce", path: "/digital-workforce" },
  ];
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialStatus = ["Subscribed"];

  const [digitalWorkforceList, setDigitalWorkforceList] = useState<
    DigitalWorkforceRow[]
  >([]);
  const [selectedDomains, setSelectedDomains] = useState<string[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
  const [filteredList, setFilteredList] = useState<DigitalWorkforceRow[]>([]);

  useEffect(() => {
    filterList();
  }, [selectedStatus, selectedDomains, digitalWorkforceList]);

  useEffect(() => {
    fetchDigitalWorkforceList();
  }, []);

  const filterList = () => {
    if (selectedDomains.length === 0 && selectedStatus.length === 0) {
      setFilteredList(digitalWorkforceList);
      return;
    }
    const filteredByDomains =
      selectedDomains.length === 0
        ? digitalWorkforceList
        : digitalWorkforceList.filter(
            (item) =>
              item.domains &&
              item.domains.some((domain) => selectedDomains.includes(domain))
          );

    const filtered =
      selectedStatus.length === 0
        ? filteredByDomains
        : filteredByDomains.filter((item) => item.subscribeStatus === true);
    setFilteredList(filtered);
  };

  const fetchDigitalWorkforceList = () => {
    getDigitalWorkforceList().then((res) => {
      let digitalWorkforceList = res;
      if (isFromWorkbench && !isPlatformAdmin()) {
        digitalWorkforceList = digitalWorkforceList.filter(
          (item: DigitalWorkforceRow) => item.subscribeStatus === true
        );
      }
      setDigitalWorkforceList(digitalWorkforceList);
    });
  };

  const onConfirm = () => {
    fetchDigitalWorkforceList();
  };

  const handleSubscribeDigitalWorkforce = (id: string) => {
    const userInfo = getUserInfo();
    const data = {
      organizationId: userInfo.currentOrganizationId,
      digitalWorkforceId: id,
      subscribedUserId: userInfo.id,
    };
    subscribeDigitalWorkforce(data)
      .then((res) => {
        onOpen();
        onConfirm();
      })
      .catch((error) => {
        console.log(error);
        toast({
          title: t("digital-workforce.msg.subscribe-error"),
          position: "top",
          status: "error",
          isClosable: true,
          duration: 3000,
        });
      });
  };

  const handleCancelSubscribeDigitalWorkforce = (
    subscribedDigitalWorkforceId: string
  ) => {
    cancelSubscribeDigitalWorkforce(subscribedDigitalWorkforceId, 0)
      .then((res) => {
        onConfirm();
        toast({
          title: t("common.btn.update-success-msg"),
          position: "top",
          status: "success",
          isClosable: true,
          duration: 3000,
        });
      })
      .catch((error) => {
        console.log(error);
        toast({
          title: t("common.btn.update-error-msg"),
          position: "top",
          status: "error",
          isClosable: true,
          duration: 3000,
        });
      });
  };

  const canSubscribe = (row: DigitalWorkforceRow) => {
    if (row.type === 1) {
      if (row.customizedOrganizationId === getCurrentOrganizationId()) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };
  return (
    <Box maxW="1240px" margin="0 auto" px="1rem">
      <Box py="1rem">
        <CustomBreadcrumb routes={breadcrumbRoutes} />
      </Box>
      <Box p="1rem" bg="white" mb="1rem">
        <Text
          fontSize="xl"
          pb={isPlatformAdmin() ? "1rem" : 0}
          color={textColor}
          fontWeight="bold"
        >
          {t("digital-workforce.list.header")}
        </Text>
      </Box>

      <Box p="1rem" mb="1rem" bgColor="white">
        <Box>
          <TagSelector
            name={t("common.status")}
            items={initialStatus}
            selectedItems={selectedStatus}
            setSelectedItems={setSelectedStatus}
          />
          <TagSelector
            name={t("digital-workforce.list.domains")}
            items={domainList}
            selectedItems={selectedDomains}
            setSelectedItems={setSelectedDomains}
          />
        </Box>
      </Box>

      <Box my="1rem">
        <SimpleGrid columns={{ base: 1, md: 4 }} spacing={5}>
          {filteredList.map((row, index) => {
            return (
              <Box
                key={index}
                bg={"#fff"}
                borderRadius="5px"
                _hover={{
                  boxShadow: "lg",
                  rounded: "md",
                  cursor: "pointer",
                }}
              >
                <Box w="100%" bg="gray.200" pos="relative" pb={4}>
                  <Flex justify="flex-end" fontSize="0.8rem">
                    <Text pr={2} pt={1}>
                      {t(_renderDigitalWorkforceType(row.type))}
                    </Text>
                  </Flex>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Avatar
                      size="xl"
                      name={row.name}
                      bg="teal.500"
                      color="white"
                    />
                  </Box>
                </Box>
                <Box px="0.8rem">
                  <Heading
                    textAlign="center"
                    fontSize={"large"}
                    mt={2}
                    mb="0.5rem"
                    h="2.4em"
                    noOfLines={2}
                  >
                    {row.name}
                  </Heading>

                  {/* Statistics */}
                  <HStack justify="center">
                    <Flex flexDirection="column" alignItems="center" w="50%">
                      <Text fontSize="2xl" fontWeight="bold" color="blue.500">
                        {row.executionCount}
                      </Text>
                      <Text fontSize="12px" color="gray">
                        {t("digital-workforce.list.number-of-executions")}
                      </Text>
                    </Flex>
                    <Flex flexDirection="column" alignItems="center" w="50%">
                      <Text fontSize="2xl" fontWeight="bold" color="blue.500">
                        {row.totalExecutionTime}h
                      </Text>
                      <Text fontSize="12px" color="gray">
                        {t("digital-workforce.list.total-execution-time")}
                      </Text>
                    </Flex>
                  </HStack>

                  <Text mt={2} textAlign="center">
                    {t("digital-workforce.list.subscribed-org")}
                  </Text>

                  <Stack direction="row" spacing={-10} justify="center" mt={1}>
                    {isEmpty(row?.subscribedOrganizations) ? (
                      <Avatar size="md" />
                    ) : (
                      <>
                        {row?.subscribedOrganizations
                          ?.slice(0, 5)
                          .map((organization) => {
                            return <Avatar size="md" name={organization} />;
                          })}
                      </>
                    )}
                  </Stack>
                  <Box py={4}>
                    {canEdit() && (
                      <>
                        {!row.subscribeStatus ? (
                          <Button
                            width={"full"}
                            fontSize={"sm"}
                            rounded={"lg"}
                            bg={"blue.400"}
                            color={"white"}
                            _hover={{
                              bg: "blue.500",
                            }}
                            _focus={{
                              bg: "blue.500",
                            }}
                            isDisabled={canSubscribe(row)}
                            onClick={() => {
                              handleSubscribeDigitalWorkforce(row.id!);
                            }}
                          >
                            {t("digital-workforce.list.subscribe")}
                          </Button>
                        ) : (
                          <Button
                            width={"full"}
                            fontSize={"sm"}
                            rounded={"lg"}
                            onClick={() => {
                              handleCancelSubscribeDigitalWorkforce(
                                row.subscribedDigitalWorkforceId!
                              );
                            }}
                          >
                            {t("digital-workforce.list.cancel-subscribe")}
                          </Button>
                        )}
                      </>
                    )}
                  </Box>
                </Box>
              </Box>
            );
          })}
        </SimpleGrid>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Alert status="success">
              <AlertIcon />
              <Box>{t("digital-workforce.msg.subscribe-success-alert")}</Box>
              <Center>
                <Button
                  onClick={() => navigate("/workbench/digital-workforce")}
                >
                  {t("common.btn.go")}
                </Button>
              </Center>
            </Alert>
            <ModalFooter></ModalFooter>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default DigitalWorkforce;
