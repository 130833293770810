import { ChakraProvider } from "@chakra-ui/react";
import { Route, Routes } from "react-router-dom";
import Register from "./pages/Auth/Register";
import Login from "./pages/Auth/Login";
import Layout from "./components/Layout";
import Active from "./pages/Auth/Active";
import Invitation from "./pages/Auth/Invitation";
import theme from "./theme";
import { useEffect, useState } from "react";
import { guestLogin } from "./services/authService";
import { setLocalInfo } from "./utils/auth";
import { setUserInfo, setUserRoles } from "./store";
import { isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import { HISTORICAL_LOGS } from "./constants/constants";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import { GoogleOAuthProvider } from "@react-oauth/google";

export const App = () => {
  const dispatch = useDispatch();
  const [isLogin, setIsLogin] = useState<boolean>(false);

  useEffect(() => {
    const latestLogVersion = HISTORICAL_LOGS[0].version;
    const storedVersion = localStorage.getItem("logVersion");

    if (storedVersion !== latestLogVersion) {
      localStorage.clear();
      localStorage.setItem("logVersion", latestLogVersion);
    }
    const token = localStorage.getItem("token");
    if (!token) {
      guestLogin().then((res) => {
        setLocalInfo(res);
        const userInfo = res.user;
        let organizationRoles: string[] = [];
        const organizations = userInfo.organizations;
        dispatch(setUserInfo(userInfo));

        if (!isEmpty(organizations)) {
          const organization = organizations.find(
            (item: any) => item.id === userInfo.currentOrganizationId
          );
          if (!!organization) {
            organizationRoles.push(
              "Organization." + organization.organizationRole
            );
          }
        }
        dispatch(setUserRoles([...userInfo.roles, ...organizationRoles]));
        setIsLogin(true);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      });
    } else {
      setIsLogin(true);
    }
  }, []);
  return (
    <ChakraProvider theme={theme}>
      <GoogleOAuthProvider clientId="765046988076-aq7foqvo782ls0ql53l50h64lu41c2mu.apps.googleusercontent.com">
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/user/active/:id" element={<Active />} />
          <Route path="/user/invitation/:id" element={<Invitation />} />
          <Route
            path="/user/forgot-password/:email"
            element={<ForgotPassword />}
          />
          <Route path={"/register"} element={<Register />} />
          {isLogin && <Route path="/*" element={<Layout />} />}
        </Routes>
      </GoogleOAuthProvider>
    </ChakraProvider>
  );
};
