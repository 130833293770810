import React, { useEffect } from "react";
import {
  Box,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
} from "@chakra-ui/react";
import { useRoutes } from "react-router-dom";
import { useDispatch } from "react-redux";
import routes from "../../config/routes";
import Nav from "../Layout/Nav";
import Sidebar from "../Layout/Sidebar";
import { userInitial } from "../../services/authService";
import { setUserInfo } from "../../store";
import Footer from "./Footer";

const Layout: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();

  useEffect(() => {
    userInitial().then((res) => {
      localStorage.setItem("userInfo", JSON.stringify(res));
      dispatch(setUserInfo(res));
    });
  }, []);

  const GetRoutes = () => useRoutes(routes);
  return (
    <Box
      display="flex"
      flexDirection="column"
      minH="100vh"
      bg={useColorModeValue("gray.100", "gray.900")}
    >
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <Sidebar onClose={onClose}></Sidebar>
        </DrawerContent>
      </Drawer>
      <Nav onOpen={onOpen}></Nav>
      <Box flex="1" mt="80px" pb={{ base: "1rem", md: "0" }}>
        <GetRoutes />
      </Box>
      <Footer></Footer>
    </Box>
  );
};

export default Layout;
