import {
  Text,
  Box,
  Button,
  Center,
  Flex,
  Heading,
  SimpleGrid,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import { useNavigate } from "react-router-dom";
import avatar1 from "../../../assets/img/bot/1.jpg";
import avatar2 from "../../../assets/img/bot/2.jpg";
import avatar3 from "../../../assets/img/bot/3.jpg";
import avatar4 from "../../../assets/img/bot/4.jpg";
import avatar5 from "../../../assets/img/bot/5.jpg";
import avatar6 from "../../../assets/img/bot/6.jpg";
import avatar7 from "../../../assets/img/bot/7.jpg";
import avatar8 from "../../../assets/img/bot/8.jpg";
import avatar9 from "../../../assets/img/bot/9.jpg";
import avatar10 from "../../../assets/img/bot/10.jpg";
import avatar11 from "../../../assets/img/bot/11.jpg";
import avatar12 from "../../../assets/img/bot/12.jpg";
import { useEffect, useState } from "react";
import { SubscribedDigitalWorkforceInfo } from "../../../models/releaseDigitalWorkforce";
import { subscribedDigitalWorkforce } from "../../../services/releaseDigitalWorkforce";
import { isEmpty } from "lodash";
import { FiPlus } from "react-icons/fi";

const CustomerDigitalWorkforce = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const avatars = [
    avatar1,
    avatar2,
    avatar3,
    avatar4,
    avatar5,
    avatar6,
    avatar7,
    avatar8,
    avatar9,
    avatar10,
    avatar11,
    avatar12,
  ];

  const breadcrumbRoutes = [
    { label: "menu.home", path: "/" },
    { label: "menu.workbench", path: "/workbench" },
    {
      label: "menu.digital-workforce",
      path: "/workbench/digital-workforce",
    },
  ];
  const items = [
    {
      title: t("release-digital-workforce.items.task"),
      path: "/workbench/release-digital-workforce/task",
    },
    {
      title: t("release-digital-workforce.items.function"),
      path: "/workbench/release-digital-workforce/function",
    },
    {
      title: t("release-digital-workforce.items.role"),
      path: "/workbench/release-digital-workforce/role",
    },
  ];

  const [digitalWorkforceList, setDigitalWorkforceList] = useState<
    SubscribedDigitalWorkforceInfo[]
  >([]);

  //获取数据
  useEffect(() => {
    fetchMyDigitalWorkforceList();
  }, []);

  const fetchMyDigitalWorkforceList = () => {
    subscribedDigitalWorkforce()
      .then((res) => {
        console.log("subscribedDigitalWorkforce", res);
        setDigitalWorkforceList(res);
      })
      .catch();
  };

  const _renderStatus = (row: SubscribedDigitalWorkforceInfo) => {
    return isEmpty(row.groupCount) ? (
      <Text color="gray">{t("common.resting")}</Text>
    ) : (
      <Text color="green">{t("common.running")}</Text>
    );
  };
  return (
    <Box maxW="1240px" margin="0 auto" p="1rem" minH="calc(100vh - 120px)">
      <Box pb="1rem">
        <CustomBreadcrumb routes={breadcrumbRoutes} />
      </Box>
      <Box minH="calc(100vh - 200px)">
        <Heading textAlign="center" mb={{ base: "2rem", md: "5rem" }}>
          {t("digital-workforce.workbench.list.title")}
        </Heading>
        {!isEmpty(digitalWorkforceList) && (
          <SimpleGrid columns={{ base: 1, md: 4 }} spacing={5}>
            {digitalWorkforceList.map((row, index) => {
              const avatar = avatars[index % 12];
              return (
                <Box
                  bg="#fff"
                  borderRadius="md"
                  p="1rem"
                  boxShadow="small"
                  transition="transform 0.3s"
                  _hover={{
                    boxShadow: "lg",
                    rounded: "md",
                    cursor: "pointer",
                    transform: "translateY(-6px)",
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box
                      w="120px"
                      h="120px"
                      bgImage={avatar}
                      bgSize="cover"
                      bgPosition="50%"
                      borderRadius="50%"
                    ></Box>
                  </Box>
                  <Center>
                    <Heading my="0.25rem" h="2.4em" size="md" noOfLines={2}>
                      {row.name}
                    </Heading>
                  </Center>
                  <Center my="0.25rem">
                    <Text h="3em" noOfLines={2}>
                      {row.description}
                    </Text>
                  </Center>

                  <Center py="0.25rem">{_renderStatus(row)}</Center>
                  <Center py="0.25rem">
                    {t("digital-workforce.workbench.list.count")}:
                    {row.groupCount}
                  </Center>
                  <Flex justifyContent="space-between" pt="0.5rem">
                    <Button
                      size="sm"
                      minW="100px"
                      isDisabled={!row.groupCount}
                      onClick={() => {
                        navigate(
                          `/workbench/digital-workforce/tasks/${row.id}`
                        );
                      }}
                    >
                      {t("digital-workforce.workbench.list.view-task")}
                    </Button>
                    <Button
                      size="sm"
                      minW="100px"
                      onClick={() =>
                        navigate(`/workbench/digital-workforce/edit/${row.id}`)
                      }
                    >
                      {t("digital-workforce.workbench.list.conf")}
                    </Button>
                  </Flex>
                </Box>
              );
            })}
          </SimpleGrid>
        )}
        <Center>
          <Button
            leftIcon={<FiPlus />}
            colorScheme="teal"
            my="2rem"
            onClick={() => navigate(`/workbench/digital-workforce/edit/new`)}
          >
            {t("digital-workforce.list.create")}
          </Button>
        </Center>
      </Box>
    </Box>
  );
};

export default CustomerDigitalWorkforce;
