import {
  Box,
  Button,
  Center,
  Heading,
  Tag,
  Text,
  Wrap,
  WrapItem,
  useClipboard,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FiChevronLeft } from "react-icons/fi";
import { useNavigate, useParams } from "react-router-dom";
import { organizationDetail } from "../../../services/organizationService";
import Empty from "../../../components/Empty";
import { OrganizationInfo } from "../../../models/auth";
import { getDigitalWorkforceById } from "../../../services/digitalWorkforceService";
import { DigitalWorkforceRow } from "../../../models/digitalWorkforce";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";

const DigitalWorkforceDetails: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { hasCopied, onCopy } = useClipboard(window.location.href);
  const [breadcrumbRoutes, setBreadcrumbRoutes] = useState<any>();
  const [digitalWorkforceInfo, setDigitalWorkforceInfo] =
    useState<DigitalWorkforceRow>();
  const params = useParams();
  const id = params.id;
  useEffect(() => {
    if (!!id) {
      getDigitalWorkforceById(id).then((res) => {
        setDigitalWorkforceInfo(res);
        setBreadcrumbRoutes([
          { label: "menu.home", path: "/" },
          { label: "menu.digital-workforce", path: "/digital-workforce" },
          {
            label: res.name,
            path: res.name,
          },
        ]);
      });
    }
  }, []);

  return (
    <Box maxW="1240px" margin="0 auto" px="1rem">
      {!!breadcrumbRoutes && (
        <Box py="1rem">
          <CustomBreadcrumb routes={breadcrumbRoutes} />
        </Box>
      )}

      {!!digitalWorkforceInfo?.name ? (
        <Box p="1rem" pb="3rem" bgColor="#fff">
          <Button
            size="sm"
            variant="ghost"
            onClick={onCopy}
            colorScheme={hasCopied ? "yellow" : "teal"}
          >
            {hasCopied ? t("common.btn.copy") : t("common.btn.share")}
          </Button>
          <Center>
            <Heading as="h5" size="lg" py="2rem" fontWeight="bold">
              {digitalWorkforceInfo?.name}
            </Heading>
          </Center>
          <Box
            display="flex"
            justifyContent="space-between"
            pt="0.5rem"
            flexDirection="column"
          >
            <Heading as="h5" size="sm" color="gray.400">
              {t("digital-workforce.list.domains")}
            </Heading>
            <Box width="100%" pt="6px" pl="6px" fontWeight="bold">
              <Wrap spacing={1} mb={2} overflow="hidden">
                {digitalWorkforceInfo.domains?.map((item) => (
                  <WrapItem key={item}>
                    <Tag variant="solid" colorScheme="cyan">
                      {item}
                    </Tag>
                  </WrapItem>
                ))}
              </Wrap>
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            pt="0.5rem"
            flexDirection="column"
          >
            <Heading as="h5" size="sm" color="gray.400">
              {t("digital-workforce.list.responsibilities")}
            </Heading>
            <Box
              width="100%"
              pt="6px"
              pl="6px"
              minHeight="30px"
              fontWeight="bold"
            >
              <Text
                px="1rem"
                className="ck-content"
                dangerouslySetInnerHTML={{
                  __html: digitalWorkforceInfo?.responsibilities,
                }}
              />
            </Box>
          </Box>
        </Box>
      ) : (
        <Empty></Empty>
      )}
    </Box>
  );
};

export default DigitalWorkforceDetails;
